import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import {
  SEO,
  Layout,
  PageHeader,
  MainWrapper,
  ThumbGrid,
  Links,
} from "components"
import LightboxComponent from "../components/LightBox/lightbox"

const GallerySection = styled.div`
  background-color: var(--whiteChalk);
`

const GalleryContent = styled.div`
  padding: 70px 0;

  > div {
    padding: 10px;
  }
`

const GalleryTemplate = ({ data, pageContext }) => {
  const [showLightbox, setShowLightbox] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  const handleOpen = i => e => {
    setShowLightbox(true)
    setSelectedImage(i)
  }
  const handleClose = () => {
    setShowLightbox(false)
    setSelectedImage(null)
  }
  const handlePrevRequest = (i, length) => e => {
    setSelectedImage((i - 1 + length) % length)
  }
  const handleNextRequest = (i, length) => e => {
    setSelectedImage((i + 1) % length)
  }

  //gallery data
  const images = data.allFile.edges
  const { currentPage } = pageContext

  //photos per pages
  const photoserPage = 9
  //pagination
  let numberOfPages = Math.ceil(data.allFile.totalCount / photoserPage)

  return (
    <Layout>
      <SEO title="Gallery" />
      <PageHeader title="Our Gallery">
        <StaticImage
          src="../images/slider/3.jpg"
          placeholder="blurred"
          alt="Menu"
          quality="100"
        />
      </PageHeader>
      <GallerySection>
        <MainWrapper>
          <GalleryContent>
            {showLightbox && selectedImage !== null && (
              <LightboxComponent
                images={images}
                handleClose={handleClose}
                handleNextRequest={handleNextRequest}
                handlePrevRequest={handlePrevRequest}
                selectedImage={selectedImage}
              />
            )}
            <ThumbGrid images={images} handleOpen={handleOpen} />
          </GalleryContent>

          <div className="d-flex justify-content-center">
            <Links currentPage={currentPage} numberOfpage={numberOfPages} />
          </div>
        </MainWrapper>
      </GallerySection>
    </Layout>
  )
}

export default GalleryTemplate

export const query = graphql`
  query galleryTemplate($skip: Int!, $limit: Int!) {
    allFile(
      filter: { sourceInstanceName: { eq: "gallery" } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      totalCount
    }
  }
`
